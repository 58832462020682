import React from "react";
import { Layout } from "components";
import { Hero, Loader } from "components/ACF";
import WordPressPreview from "components/WordPressPreview/WordPressPreview";
import { ProjectByIdQuery } from "codegen";
import "./project-single.scss";
import { PageProps, graphql } from "gatsby";

interface CPTPageContext {
  id: string;
  title: string;
  cptType: string;
}

const ProjectSingle = ({
  data: { project, next, previous },
}: PageProps<ProjectByIdQuery, CPTPageContext>): JSX.Element => {
  const { title, acfLayout, content, seo, featuredImage } = project;

  return (
    <Layout pageTitle={title} seo={seo}>
      <>
        <Hero
          data={{
            eyebrow: "Project gallery",
            content: `<h1>${title}</h1>`,
            image: featuredImage?.node,
          }}
          location={seo.breadcrumbs}
        />
        {acfLayout?.layout?.length > 0 ? (
          <Loader
            data={acfLayout.layout.map(({ ...layout }) => ({
              ...layout,
            }))}
            location={seo?.breadcrumbs}
          />
        ) : (
          content && (
            <div className="layout">
              <div dangerouslySetInnerHTML={{ __html: content }} />
            </div>
          )
        )}
      </>
    </Layout>
  );
};

export default WordPressPreview({ postType: "projects" })(ProjectSingle);

export const CPTQueryById = graphql`
  query ProjectById($id: String!, $previousPostId: String, $nextPostId: String) {
    # selecting the current page by id
    project: wpProjectGallery(id: { eq: $id }) {
      ...ProjectFields
      acfLayout {
        fieldGroupName
        layout {
          ...HeroQuery
          ...FlexibleBlockQuery
          ...FeaturedPostsQuery
          ...RelatedPagesQuery
          ...SliderGalleryQuery
        }
      }
    }
    previous: wpProjectGallery(id: { eq: $previousPostId }) {
      uri
      title
    }
    next: wpProjectGallery(id: { eq: $nextPostId }) {
      uri
      title
    }
  }
`;
